<template>
  <div class="details" v-if="item">
    <div class="details__top-bg"/>
    <div class="details__main-green">
      <img :src="item.featured_image || '/images/default-photo.png'" class="details__img"/>
      <div class="details__wrap">
        <div class="flex align-center mg-t-15">
          <img v-if="isPlace" class="details__img-btn" :class="{ 'gray-scale': hasCheckedIn }" :src="checkinImg" @click="checkin"/>
          <img class="details__img-btn" v-if="item.vr_link" @click="openVr" src="/images/vr.png"/>
        </div>
        <div class="mg-t-20">
          <h2>{{title}}</h2>
          <h3 v-if="isPlace">{{address}}</h3>
          <h3 v-if="isPlace">{{item.latitude}}, {{item.longitude}}</h3>
        </div>
      </div>
      <div class="details__mid-bg"/>
      <div class="details__wrap mg-t-15" v-html="description"/>
      <div class="gallery mg-t-10" v-if="item.media?.length">
        <div class="gallery__content" :style="{ left: galleryLeftPosition }">
          <div class="gallery__img" v-for="(media, index) in item.media" :key="media.id" @click="openImageModal(index)">
            <img :src="media.original_url"/>
          </div>
        </div>
        <img src="/images/nav-left.png" class="gallery__left" v-if="hasMoreLeft" @click="galleryNav('back')"/>
        <img src="/images/nav-right.png" class="gallery__right" v-if="hasMoreRight" @click="galleryNav('next')"/>
      </div>
      <div class="details__wrap mg-t-10">
        <div v-if="item.questionnaire" @click="toggleQuizModal" class="flex align-center justify-center">
          <img src="/images/quiz-en.png" class="details__quiz"/>
        </div>
        <div class="flex align-center flex-wrap mg-t-20 pd-b-15">
          <router-link :to="`/places?tagId=${tag.id}&tagName=${tagName(tag)}&searchTerm=${tagName(tag)}`" class="details__chip" v-for="tag in item.tags" :key="tag.id">{{tagName(tag)}}</router-link>
        </div>
      </div>
    </div>
    <div class="details__bottom-bg"/>
    <div class="details__wrap-sm flex direction-column" v-if="isPlace">
      <router-link v-for="history in item.histories" :key="history.id" :to="`/histories/${history.id}`" >{{historyTitle(history)}}</router-link>
    </div>
    <!--  TODO: find a better way handle video here   -->
    <div class="details__wrap-sm video-player" v-if="item.video_link">
      <iframe class="video-player__video" :src="`https://www.youtube.com/embed/${youtubeId(item.video_link)}`" allow="fullscreen;"/>
    </div>
    <div class="details__city-bg"/>

    <vue-final-modal
      v-model="showImgModal"
      classes="modal-container"
      content-class="modal-content w-600"
    >
      <div class="modal-title">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="toggleImgModal"/>
        </div>
        <h3>{{title}}</h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body" v-if="this.item.media.length">
        <img :src="this.item.media[this.openedPicture].original_url" class="gallery__expanded-img" />
      </div>
    </vue-final-modal>

    <vue-final-modal
      v-model="showRewardModal"
      classes="modal-container"
      content-class="modal-content modal-contained w-300"
    >
      <div class="modal-title no-bg">
        <div class="modal-title-left">
          <img src="/images/close-icon.png" @click="showRewardModal = false"/>
        </div>
        <h3></h3>
        <div class="modal-title-right"/>
      </div>
      <div class="modal-body pd-b-20 pd-l-20 pd-r-20 points-modal">
        <h3>{{$t('details.got')}}</h3>
        <p>{{awardedPoints}} {{$t('details.pieces')}}</p>
        <img src="/images/film.png"/>
        <button class="btn btn--orange blocked" @click="showRewardModal = false">{{$t('details.continue')}}</button>
      </div>
    </vue-final-modal>

    <quiz-modal :show="showQuizModal" :quiz="item.questionnaire" @toggle="toggleQuizModal" @completed="quizCompleted"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuizModal from '@/components/quiz/quiz'

export default {
  name: 'DetailsPage',
  components: { QuizModal },
  props: [
    'item',
    'checkinPoints',
    'type'
  ],
  data () {
    return {
      videoPaused: true,
      showImgModal: false,
      showQuizModal: false,
      showRewardModal: false,
      galleryIndex: 0,
      awardedPoints: 0,
      openedPicture: 0
    }
  },
  computed: {
    ...mapGetters({
      language: 'auth/language'
    }),
    checkinImg () {
      return `/images/checkin-${this.language}.png`
    },
    title () {
      if (this.isPlace) {
        return this.item.name[this.language] || this.item.name[Object.keys(this.item.name)[0]]
      } else {
        return this.item.title[this.language] || this.item.title[Object.keys(this.item.title)[0]]
      }
    },
    description () {
      return this.item.description[this.language] || this.item.description[Object.keys(this.item.description)[0]]
    },
    address () {
      return this.item.address[this.language] || this.item.address[Object.keys(this.item.address)[0]]
    },
    vr_link () {
      return this.item.vr_link[this.language] || this.item.vr_link[Object.keys(this.item.vr_link)[0]]
    },
    hasCheckedIn () {
      console.log('detailvue isWithDistance' + !!this.item.isWithinDistance)
      console.log('detailvue localStorage loginToken' + window.localStorage.getItem('loginToken'))
      console.log('detailvue sessionStorage loginToken' + window.sessionStorage.getItem('loginToken'))
      return !!this.item.checkins.length || !this.item.isWithinDistance
      // console.log('detailsvue' + this.item.checkins.length)
      // return !!this.item.checkins.length
    },
    hasMoreLeft () {
      return this.galleryIndex > 0
    },
    hasMoreRight () {
      return this.galleryIndex < this.item.media.length
    },
    galleryLeftPosition () {
      return (-this.galleryIndex * 110) + 'px'
    },
    isPlace () {
      return this.type === 'place'
    },
    vr_link_lang () {
      return this.item.vr_link[this.language] || this.item.vr_link[Object.keys(this.item.vr_link)[0]]
    }
  },
  mounted () {
  },
  methods: {
    toggleQuizModal (val) {
      console.log(JSON.stringify(val))
      this.showQuizModal = val
    },
    quizCompleted (points) {
      this.awardedPoints = points
      this.showQuizModal = false
      this.showRewardModal = true
    },
    checkin () {
      if (!this.hasCheckedIn) {
        this.$emit('checkin')
      }
    },
    openVr () {
      if (this.isPlace) {
        window.open(this.vr_link_lang, '_blank').focus()
      } else {
        window.open(this.vr_link_lang, '_blank').focus()
      }
    },
    toggleVideo () {
      if (this.$refs.video.paused) {
        this.$refs.video.play()
        this.videoPaused = false
      } else {
        this.$refs.video.pause()
        this.videoPaused = true
      }
    },
    tagName (tag) {
      return tag.name[this.language] || tag.name[Object.keys(tag.name)[0]]
    },
    historyTitle (history) {
      return history.title[this.language] || history.title[Object.keys(history.title)[0]]
    },
    openImageModal (index) {
      this.openedPicture = index
      this.toggleImgModal()
    },
    galleryNav (type) {
      if (type === 'back') {
        this.galleryIndex = this.galleryIndex - 1
      } else {
        this.galleryIndex = this.galleryIndex + 1
      }
    },
    toggleImgModal () {
      this.showImgModal = !this.showImgModal
    },
    youtubeId (url) {
      /* eslint-disable */
      if(url === null) {
        return null
      }
      var match = url.match(/^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/)
      if (match && match[2].length === 11) {
        return match[2]
      } else {
        return null
      }
      /* eslint-enable */
    }
  },
  watch: {
    checkinPoints () {
      this.awardedPoints = this.checkinPoints
      this.showRewardModal = true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
